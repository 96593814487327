import { createSlice } from "@reduxjs/toolkit";
import {
  removeAuthToken,
  setUser,
  removeUser,
} from "../utils/authentication";

const initialState = {
  user: null,
  userDetails: null,
  userEmail: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    
    setUser(state, action) {
      if (action.payload === null) {
        state.user = null;
        state.userEmail = null;
        return;
      }
      
      // Handle both object format and direct userId format
      if (typeof action.payload === 'object') {
        const { userId, email } = action.payload;
        state.user = userId;
        state.userEmail = email;
        setUser(userId);
      } else {
        state.user = action.payload;
        setUser(action.payload);
      }
    },

    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },

    logout(state) {
      state.user = null;
      state.userEmail = null;
      removeAuthToken();
      removeUser();
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;

export const selectCurrentToken = (state) => state.auth.token;
