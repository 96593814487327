import styles from "./Form.module.css";
import { motion } from "framer-motion";

const Form = ({ children, className, ...props }) => {
  return (
    <form
      // initial={{ opacity: 0, y: 30 }}
      // animate={{ opacity: 1, y: 0 }}
      // layout
      {...props}
      className={`${styles.form} ${className || ""}`}
    >
      {children}
    </form>
  );
};

export default Form;
