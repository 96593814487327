import styles from "./ErrorComponent.module.css";
import Button from "../Button/Button";
import { account } from "../../../lib/appwrite";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../store/auth-slice";
import { getTeams, getTeam, getTeamListMemberships } from "../../../lib/auth";
const ErrorComponent = ({ errorMessage, className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
const {user} = useSelector(state=>state.auth);
  const handleLogout = async () => {
    try {
      await account.deleteSession("current");
      localStorage.removeItem("token");
      dispatch(authActions.setUser(null));
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  const handleSessionDetails = async () => {
    const session = await account.getSession("current");
    const accountDetails = await account.get();
    // const teamsList = await getTeams();
    // const team = await getTeam(teamsList.teams[0].$id);
    const teamMemberships = await getTeamListMemberships();
    console.log("session ", session);
    console.log("accountDetails ", accountDetails);
    console.log("teamMemberships ", teamMemberships);
  };
  return (
    <div className={`${styles.error} ${className}`}>
      {errorMessage}
      
      <Button buttonstyle="default-blue" onClick={handleSessionDetails}>session details </Button>
      <Button buttonstyle="default-blue" onClick={handleLogout}>Logout </Button>
    </div>
  );
};

export default ErrorComponent;
