import { account, OAuthProvider, teams } from "./appwrite";
import { URL__REDIRECT_GOOGLE } from "../consts/APIConstants";

export const loginWithGoogle = async () => {
  try {
    account.createOAuth2Session(
      OAuthProvider.Google, // provider
      URL__REDIRECT_GOOGLE, // redirect here on success
      URL__REDIRECT_GOOGLE, // redirect here on failure
      ["email", "profile", "openid"] // scopes (optional)
    );
  } catch (error) {
    console.error(error);
  }
};

export const logoutUser = async () => {
  try {
    await account.deleteSession("current");
  } catch (error) {
    console.error(error);
  }
};

export const getUser = async () => {
  try {
    return await account.get();
  } catch (error) {
    console.error(error);
  }
};

export const getSession = async () => {
  try {
    return await account.getSession("current");
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getTeam = async (teamId) => {
  try {
    return await teams.get(teamId);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getTeams = async () => {
  try {
    return await teams.list();
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getTeamListMembership = async (teamId) => {
  try {
    return await teams.listMemberships(teamId);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getTeamListMemberships = async () => {
  try {
    const teamsList = await getTeams();
    let teamMemberships = [];
    for (const team of teamsList.teams) {
      const teamMembership = await getTeamListMembership(team.$id);
      teamMemberships.push(teamMembership);
    }
    return teamMemberships;
  } catch (error) {
    console.error(error);
    return [];
  }
};
