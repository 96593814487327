import { useEffect } from "react";
import {
  useLocation,
  Navigate,
  Outlet,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";
import HomePage from "../components/Home/HomePage";
import LoadingComponent from "../components/UI/LoadingIndicator/LoadingComponent";
import { getSession } from "../lib/auth";

const RequiredAuth = () => {
  const isValidSession = useLoaderData();
  const location = useLocation();
  const revalidator = useRevalidator();
  useEffect(() => {
    revalidator.revalidate();
  }, [location]);

  return revalidator.state == "loading" ? (
    <LoadingComponent />
  ) : isValidSession ? (
    <HomePage>
      <Outlet />
    </HomePage>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequiredAuth;

export const loader = async () => {
  const session = await getSession();
  if (session) {
    return true;
  }
  return false;
};
