import SignInUpLandingPage from "../components/Login/SignInUpLandingPage.jsx";
import { Navigate, useLoaderData, useLocation } from "react-router-dom";

const SignUp = () => {
  const isValidSession = useLoaderData();
  const location = useLocation();
  return isValidSession ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <SignInUpLandingPage isSignUp={true}/>
  );
  // return <Navigate to="/home" state={{ from: location }} replace />;
};
export default SignUp;