import styles from "./OTPVerificationForm.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import { useVerifyOtpMutation } from "../../Register/api/registerApi-slice.jsx";
import { loginActions } from "../../../store/login-slice.jsx";
import OTPInput from "../../UI/Input/OTPInput.jsx";
import MessageDialog from "../../UI/Modal/MessageDialog.jsx";
import Button from "../../UI/Button/Button.jsx";
import Form from "../../UI/Form.jsx";
import {
  BTN__VERIFY_OTP,
  BTN__RESEND,
  LBL__OTP_PART_1,
  LBL__OTP_PART_2,
  ERR_DEFAULT,
  BTN__RETRY,
  BTN__CLOSE,
} from "../../../consts/TextConstants.jsx";
import { useNavigate } from "react-router-dom";
import {
  resendCode,
  clearLoginAttemptInfo,
  consumeCode,
} from "supertokens-web-js/recipe/passwordless";
import { authActions } from "../../../store/auth-slice.jsx";
import { useLazyGetFlagsQuery } from "../../Dashboard/apis/dashboardApi-slice.jsx";
import Session from "supertokens-web-js/recipe/session";
import { account, ID } from "../../../lib/appwrite";
import { logoutUser } from "../../../lib/auth";

const OTPVerificationForm = ({ ...props }) => {
  const otpLength = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [enableResend, setEnableResend] = useState(true);
  const [message, setMessage] = useState({ show: false, isError: false });
  const [isLoading, setIsLoading] = useState(false);

  const { user, userEmail: email } = useSelector((state) => state.auth);

  const [trigger] = useLazyGetFlagsQuery();

  const handleOtpInputComplete = (value) => {
    setOtp(value);
  };

  const handleResendClick = async () => {
    setEnableResend(false);
    setTimeout(() => {
      setEnableResend(true);
    }, 60000);
    try {
      // let response = await resendCode();
      const sessionToken = await account.createEmailToken(ID.unique(), email);

      // if (response.status == "RESTART_FLOW_ERROR") {
      //   // this can happen if the user has already successfully logged in into
      //   // another device whilst also trying to login to this one.

      //   // we clear the login attempt info that was added when the createCode function
      //   // was called - so that if the user does a page reload, they will now see the
      //   // enter email / phone UI again.

      //   await clearLoginAttemptInfo();
      //   // dispatch(loginActions.setShowOTPForm(false));
      //   // window.alert("Login failed. Please try again");
      //   setMessage({
      //     show: true,
      //     isError: true,
      //     title: "Error",
      //     message: "Login failed. Please try again",
      //   });
      //   // navigate("/")
      // } else {
      // OTP resent successfully.
      setMessage({
        show: true,
        isError: false,
        title: "",
        message: "Please check your email for the OTP",
      });
      // window.alert("Please check your email for the OTP");
      // }
    } catch (err) {
      let errMessage = "Oops! Something went wrong.";
      // if (err.isSuperTokensGeneralError === true) {
      //   // this may be a custom error message sent from the API by you.
      //   // window.alert(err.message);
      //   errMessage = err.message;
      // }
      setMessage({
        show: true,
        isError: true,
        title: "Error",
        message: errMessage,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const session = await account.createSession(user, otp);
      //   let response = await consumeCode({
      //     userInputCode: otp,
      //   });
      //   if (response.status === "OK") {
      //     // we clear the login attempt info that was added when the createCode function
      //     // was called since the login was successful.
      //     await clearLoginAttemptInfo();
      // dispatch(authActions.setUser(response.user.emails[0]));
      try {
        const flagResult = await trigger().unwrap();
        if (flagResult?.flags?.isBasicInfoComplete) {
          navigate("/home");
        } else {
          dispatch(loginActions.setShowUserDetailsForm(true));
        }
      } catch (error) {
        await logoutUser();
      } finally {
        dispatch(loginActions.setShowOTPForm(false));
      }
      //   } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
      //     // the user entered an invalid OTP
      //     // window.alert("Wrong OTP! Please try again. Number of attempts left: " + (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount));
      //     setMessage({
      //       show: true,
      //       isError: false,
      //       title: "",
      //       message:
      //         "Wrong OTP! Please try again. Number of attempts left: " +
      //         (response.maximumCodeInputAttempts -
      //           response.failedCodeInputAttemptCount),
      //     });
      //   } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
      //     // it can come here if the entered OTP was correct, but has expired because
      //     // it was generated too long ago.
      //     // window.alert("Old OTP entered. Please regenerate a new one and try again");
      //     setMessage({
      //       show: true,
      //       isError: false,
      //       title: "",
      //       message: "Old OTP entered. Please regenerate a new one and try again",
      //     });
      //   } else {
      //     // this can happen if the user tried an incorrect OTP too many times.
      //     // or if it was denied due to security reasons in case of automatic account linking

      //     // we clear the login attempt info that was added when the createCode function
      //     // was called - so that if the user does a page reload, they will now see the
      //     // enter email / phone UI again.
      //     await clearLoginAttemptInfo();
      //     // dispatch(loginActions.setShowOTPForm(false));
      //     // window.alert("Login failed. Please try again");
      //     setMessage({
      //       show: true,
      //       isError: true,
      //       title: "Error",
      //       message: "Login failed. Please try again",
      //     });
      //     // navigate("/")
      //   }
    } catch (err) {
      let errorMessage = "Oops! Something went wrong.";
      // if (err.isSuperTokensGeneralError === true) {
      //   // this may be a custom error message sent from the API by you.
      //   // window.alert(err.message);
      //   errorMessage = err.message;
      // }

      setMessage({
        show: true,
        isError: true,
        title: "Error",
        message: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseMessage = () => {
    const isError = message.isError;
    setMessage({ show: false });
    if (isError) {
      dispatch(loginActions.setShowOTPForm(false));
      navigate("/");
    }
  };

  return (
    <div className={styles.main}>
      <AnimatePresence>
        {message.show && (
          <MessageDialog
            isError={message?.isError}
            title={message?.title}
            message={message?.message}
            buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
            onClose={handleCloseMessage}
          />
        )}
      </AnimatePresence>
      <Form method="post" className={styles.form} onSubmit={handleSubmit}>
        <p className={styles.label_otp}>
          {LBL__OTP_PART_1} <span>{email}</span> {LBL__OTP_PART_2}
        </p>
        <OTPInput
          className={styles.otp_input}
          onComplete={handleOtpInputComplete}
          otpLength={otpLength}
        />
        <Button
          className={styles.btn_resend}
          disabled={!enableResend}
          onClick={handleResendClick}
        >
          {BTN__RESEND}
        </Button>
        <Button
          buttonstyle="fixed_width_blue_login"
          type="submit"
          disabled={otp.length != otpLength || isLoading}
          isLoading={isLoading}
        >
          {BTN__VERIFY_OTP}
        </Button>
      </Form>
    </div>
  );
};

export default OTPVerificationForm;
