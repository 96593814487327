import styles from "./ModalLarge.module.css";
import { motion } from "framer-motion";
import ReactDOM from "react-dom";

const ModalOverlay = ({ children, ...props }) => {
  const overlay = {
    hidden: { opacity: 0, y: 1000 },
    visible: { opacity: 1, y: 0 },
  };
  const backdrop = {
    hidden: { backdropFilter: "blur(0)" },
    visible: { backdropFilter: "blur(0.4rem)" },
  };
  return (
    <motion.div
      variants={backdrop}
      initial="hidden"
      animate="visible"
      exit="hidden"
      transition={{duration: 0.6, type: "tween"}}
      className={styles.backdrop}
      onClick={props.onClose}
    >
      <motion.div
        variants={overlay}
        initial="hidden"
        animate="visible"
        exit="hidden"
        transition={{ ease: "linear", duration: 0.6, type: "tween" }}
        className={styles.main}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

const ModalLarge = (props) => {
  const portalElement = document.getElementById("overlays");
  if (!portalElement) return null;
  return (
    <>
      {ReactDOM.createPortal(
        <ModalOverlay onClose={props.onClose}>{props.children}</ModalOverlay>,
        portalElement
      )}
    </>
  );
};

export default ModalLarge;
