import { Client, Account, OAuthProvider, Teams } from "appwrite";

export const client = new Client();

client
  .setEndpoint(import.meta.env.VITE_APPWRITE_END_POINT)
  .setProject(import.meta.env.VITE_APPWRITE_PROJECT_ID);

export const account = new Account(client);
export const teams = new Teams(client);
export { ID } from "appwrite";
export { OAuthProvider };