import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../UI/LoadingIndicator/LoadingIndicator.jsx";
// import { useGoogleCallBackQuery } from "./apis/googleLoginApi-slice";
import styles from "./GoogleLoginPage.module.css";
import { signInAndUp } from "supertokens-web-js/recipe/thirdparty";
import Session from "supertokens-web-js/recipe/session";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth-slice";
import { useLazyGetFlagsQuery } from "../Dashboard/apis/dashboardApi-slice.jsx";
import { loginActions } from "../../store/login-slice.jsx";
import MessageDialog from "../UI/Modal/MessageDialog.jsx";
import { BTN__RETRY, BTN__CLOSE } from "../../consts/TextConstants.jsx";
import LoadingComponent from "../UI/LoadingIndicator/LoadingComponent.jsx";
import { account } from "../../lib/appwrite";
import { logoutUser } from "../../lib/auth";
let isFirstTime = true;

const GoogleLoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");
  const dispatch = useDispatch();
  const [trigger] = useLazyGetFlagsQuery();
  const [message, setMessage] = useState({ show: false, isError: false });

  const handleGoogleCallback = async () => {
    // try {
    //   const response = await signInAndUp();

    //   if (response.status === "OK") {
    //     dispatch(authActions.setUser(response.user.emails[0]));
    //     if (
    //       response.createdNewRecipeUser &&
    //       response.user.loginMethods.length === 1
    //     ) {
    //       // sign up successful
    //     } else {
    //       // sign in successful
    //     }
    try {
      const session = await account.get();

      const flagResult = await trigger().unwrap();
      if (flagResult?.flags?.isBasicInfoComplete) {
        navigate("/home");
      } else {
        dispatch(loginActions.setShowUserDetailsForm(true));
        navigate("/", { state: { showUserDetailsPage: true } });
      }
    } catch (error) {
      await logoutUser();
      console.log(error);
    }
    // } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
    //   // window.alert(response.reason);
    //   setMessage({
    //     show: true,
    //     isError: true,
    //     title: "Error",
    //     message: response.reason,
    //   });
    // } else {
    //   // SuperTokens requires that the third party provider
    //   // gives an email for the user. If that's not the case, sign up / in
    //   // will fail.

    //   // As a hack to solve this, you can override the backend functions to create a fake email for the user.

    //   setMessage({
    //     show: true,
    //     isError: true,
    //     title: "Error",
    //     message:
    //       "No email provided by social login. Please use another form of login",
    //   });
    //   // navigate("/");
    //   // window.location.assign("/"); // redirect back to login page
    // }
    // } catch (err) {
    //   let errorMessage = "Oops! Something went wrong.";
    //   if (err.isSuperTokensGeneralError === true) {
    //     // this may be a custom error message sent from the API by you.
    //     errorMessage = err.message;
    //   }
    //   setMessage({
    //     show: true,
    //     isError: true,
    //     title: "Error",
    //     message: errorMessage,
    //   });
    //   // navigate("/");
    // }
  };
  useEffect(() => {
    if (isFirstTime) {
      isFirstTime = false;
      handleGoogleCallback();
    }
  }, []);

  const handleCloseMessage = () => {
    const isError = message.isError;
    setMessage({ show: false });
    if (isError) {
      navigate("/");
    }
  };

  return (
    <div className={styles.main}>
      <LoadingComponent />
      {message.show && (
        <MessageDialog
          isError={message?.isError}
          title={message?.title}
          message={message?.message}
          buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default GoogleLoginPage;
