import { useEffect } from "react";
import LoginPage from "../components/Login/SignInUpPage.jsx";
import SignInUpLandingPage from "../components/Login/SignInUpLandingPage.jsx";
import { Navigate, useLoaderData, useLocation, useRevalidator } from "react-router-dom";
import LoadingComponent from "../components/UI/LoadingIndicator/LoadingComponent.jsx";

const Login = () => {
  const isValidSession = useLoaderData();
  const location = useLocation();
  const revalidator = useRevalidator();
  
  useEffect(() => {
    revalidator.revalidate();
  }, [location]);

  return revalidator.state == "loading" ? (
    <LoadingComponent />
  ) : isValidSession && !location.state?.showUserDetailsPage ? (
    <Navigate to="/home" state={{ from: location }} replace />
  ) : (
    <SignInUpLandingPage />
  );
};

export default Login;
