import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/Login";
import DashboardPage from "./pages/Dashboard";
import RootPage from "./pages/Root";
import SignUpPage from "./pages/SignUp";
import CreatePassword from "./pages/CreatePassword.jsx";
import EntitiesPage from "./pages/Entities.jsx";
import TentativePage from "./pages/Tentative.jsx";
import { userLoginAction } from "./components/Login/actions/login-actions.jsx";
import RequiredAuth, { loader as authLoader } from "./pages/RequiredAuth.jsx";
import CapTablePage from "./pages/CapTable.jsx";
import CompliancePage from "./pages/Compliance.jsx";
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import Passwordless from "supertokens-web-js/recipe/passwordless";
import ThirdParty from "supertokens-web-js/recipe/thirdparty";
import { BASE_URL, CONTEXT_PATH_AUTH, URL__WEBSITE_DOMAIN } from "./consts/APIConstants.jsx";
import GoogleLoginPage from "./pages/GoogleLogin.jsx";
import FinancePage from "./pages/Finance.jsx";
import ZohoBooksPage from "./pages/ZohoBooks.jsx";
import ServicesPage from "./pages/Services.jsx";
import ErrorPage from "./pages/Error.jsx";
import EntityDetailsPage from "./pages/EntityDetails.jsx";

// SuperTokens.init({
//   appInfo: {
//     apiDomain: BASE_URL,
//     apiBasePath: CONTEXT_PATH_AUTH,
//     websiteDomain: URL__WEBSITE_DOMAIN,
//     appName: "Merze.io",
//     cookieDomain: BASE_URL
//   },
//   recipeList: [
//     Session.init({ tokenTransferMethod: "header" }),
//     Passwordless.init(),
//     ThirdParty.init(),
//   ],
// });

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LoginPage />,
        // action: userLoginAction,
        loader: authLoader,
      },
      {
        path: "signup",
        element: <SignUpPage />,
        loader: authLoader,
      },
      {
        path: "createpassword",
        element: <CreatePassword />,
      },
      {
        path: "/auth/callback/google",
        element: <GoogleLoginPage />,
      },
      {
        path: "/zoho/callback",
        element: <ZohoBooksPage />,
      },
      {
        path: "home",
        element: <RequiredAuth />,
        loader: authLoader,
        children: [
          {
            index: true,
            element: <DashboardPage />,
          },
          {
            path: "entities",
            element: <EntitiesPage />,
          },
          {
            path: "entities/:id",
            element: <EntityDetailsPage />,
          },
          {
            path: "compliance",
            element: <CompliancePage />,
          },
          {
            path: "finance",
            element: <FinancePage />,
          },
          {
            path: "services",
            element: <ServicesPage />,
          },
          {
            path: "subscriptions",
            children: [
              // { index: true, element: <Subscription />},
              { index: true, element: <TentativePage /> },
              { path: "payment", element: <TentativePage /> },
              // { path: "payment", element: <PaymentPage /> },
            ],
          },
          {
            path: "chat",
            element: <TentativePage />,
          },
          {
            path: "documents",
            element: <TentativePage />,
          },
          {
            path: "needhelp",
            element: <TentativePage />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
